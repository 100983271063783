@import url('https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap');

aside {
    display: flex;
    flex-direction: column;
    justify-content: space-between;    
    height: 100%;
    position: relative;
    padding: 12% 0 17% 0;
    overflow: hidden;  
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
    opacity: 0.9;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.6);
      z-index: -1;  
    }

    section {
        text-align: center;
    }

    img {
        width: 100%;
    }

    h1, h2 {
        font-family: "Titillium Web";
        text-transform: uppercase;
    }

    h1 {
        font-size: 3.8vw;
        font-weight: bold; 
    }

    h2 {
        text-decoration: underline;
        font-size: 3vw;
        font-weight: 900;
        letter-spacing: 4px;
    }

    p {
        font-size: 1.5vw;
    }
}

@media only screen and (max-width: 600px) {
    aside {
      display:none;
    }
  }