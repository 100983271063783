marquee {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    width: 100%;
    background-color: #333;
    padding: 0.6rem;

    span {
        font-size: 1.5vw;
        font-weight: 400;
        letter-spacing: 4px;
        color: #ffffff;
        padding: 0 1em;
    }
}

@media only screen and (max-width: 600px) {
    marquee {
      display: none;
    }
  }