section{
    ul{  
      padding: 0 6%;
      li{
        display: grid;
        text-align: left;
        grid-template-columns: 1fr 3fr 1fr;

        p:last-child {
          text-align: right;
        }
      }
    }
  }