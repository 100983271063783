@import url(https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*{padding:0;margin:0;box-sizing:border-box}#container{background:#f0f0f0;width:100%;height:100vh;display:grid;grid-template-columns:2fr 5fr}#container #main{padding:0.2rem 3rem}@media only screen and (max-width: 600px){#container{grid-template-columns:7fr}}.clouds{background-image:url(/static/media/clouds.159df5e1.jpg)}.snow{background-image:url(/static/media/snow.7df6b15a.jpg)}.rain{background-image:url(/static/media/rain.a76414f0.jpg)}.mist,.fog,.drizzle{background-image:url(/static/media/foggy.370675e9.jpg)}.sunny{background-image:url(/static/media/sunny.a50c7bba.jpg)}.mist{background-image:url(/static/media/mist.9f04d5ab.jpg)}.foggy{background-image:url(/static/media/foggy.370675e9.jpg)}.clear{background-image:url(/static/media/clear.85175e7a.jpg)}.light-rain{background-image:url(/static/media/light-rain.49ec774b.jpg)}.partly-cloudy{background-image:url(/static/media/partly-cloudy.3633a093.jpg)}.windy{background-image:url(/static/media/windy.223ac20e.jpg)}.thunderstorm{background-image:url(/static/media/thunderstorm.a110652a.jpg)}

aside{display:flex;flex-direction:column;justify-content:space-between;height:100%;position:relative;padding:12% 0 17% 0;overflow:hidden;background-repeat:no-repeat;background-size:cover;color:white;opacity:0.9}aside::before{content:"";position:absolute;top:0;left:0;right:0;bottom:0;background-color:rgba(0,0,0,0.6);z-index:-1}aside section{text-align:center}aside img{width:100%}aside h1,aside h2{font-family:"Titillium Web";text-transform:uppercase}aside h1{font-size:3.8vw;font-weight:bold}aside h2{text-decoration:underline;font-size:3vw;font-weight:900;letter-spacing:4px}aside p{font-size:1.5vw}@media only screen and (max-width: 600px){aside{display:none}}


.dish{text-align:left;margin:0 auto;width:85%;border:0 none}.dish .active td{color:white;font-weight:600}.dish td{border:0 none;font-size:1.4vw;color:#aaa}.dish td:first-child{width:30%;text-transform:capitalize;vertical-align:top}

section ul{padding:0 6%}section ul li{display:grid;text-align:left;grid-template-columns:1fr 3fr 1fr}section ul li p:last-child{text-align:right}

marquee{position:absolute;left:0;bottom:0;display:flex;width:100%;background-color:#333;padding:0.6rem}marquee span{font-size:1.5vw;font-weight:400;letter-spacing:4px;color:#ffffff;padding:0 1em}@media only screen and (max-width: 600px){marquee{display:none}}

table{width:100%}th{padding:1rem 0rem;text-align:left !important}th:first-child{width:12%}th:nth-child(2){width:30%;text-transform:capitalize}th:nth-child(3){width:25%;text-transform:capitalize}th:nth-child(4){width:22%}th:last-child{width:11%;text-align:right !important}td{font-size:2vw;border-top:solid 1px #000;padding:0.8% 0}td.room{text-align:right}td:nth-child(2){text-transform:capitalize}td:nth-child(3){text-transform:capitalize}thead{font-size:2vw;font-family:"Titillium Web";border-bottom:2px solid black}#tim{padding-top:5%;text-align:center;margin:auto;width:100%;height:100%;background-image:url(/static/media/tim.048d2ba8.jpg);background-repeat:no-repeat;background-position-x:center;background-position-y:35%;background-size:40%}#tim h1{font-size:5rem}

