table {
    width: 100%;
}

th {
    padding: 1rem 0rem;
    text-align: left !important;
    &:first-child {
        width: 12%;
    }
    &:nth-child(2) {
        width: 30%;
        text-transform: capitalize;
    }
    &:nth-child(3) {
        width: 25%;
        text-transform: capitalize;
    }
    &:nth-child(4) {
        width: 22%;
    }
    &:last-child {
        width: 11%;
        text-align: right !important;
    }
}

td {
    font-size: 2vw;
    border-top: solid 1px #000;
    padding: 0.8% 0;
    &.room {
        text-align: right;
    }
    &:nth-child(2) {
        text-transform: capitalize;        
    }
    &:nth-child(3) {
        text-transform: capitalize;        
    }
}



thead {
    font-size: 2vw;
    font-family: "Titillium Web";
    border-bottom: 2px solid black;
}



#tim {
    padding-top: 5%;
    text-align: center;
    margin: auto;
    width: 100%;
    height: 100%;
    background-image: url('../../assets/images/widgets/tim.jpg');
    background-repeat: no-repeat;
    background-position-x: center;
    background-position-y: 35%;
    background-size: 40%;
    h1 {
        font-size: 5rem;
    }
}