.dish {
    text-align: left;
    margin: 0 auto;
    width: 85%;
    border: 0 none;

    .active {
        td {
            color: white;
            font-weight: 600;
        }
    }

    td {
        border: 0 none;
        font-size: 1.4vw;
        color: #aaa;
        &:first-child {
            width: 30%;
            text-transform: capitalize;
            vertical-align: top;
        }
    }
}