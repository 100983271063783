* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

#container {
  background: rgb(240, 240, 240);
  width: 100%;
  height: 100vh;
  display: grid;
  grid-template-columns: 2fr 5fr;

  #main {
    padding: 0.2rem 3rem;
  }
}

@media only screen and (max-width: 600px) {
  #container {
    grid-template-columns: 7fr;
  }
}

.clouds {
  background-image: url("assets/images/weather/clouds.jpg");
}
.snow {
  background-image: url("assets/images/weather/snow.jpg");
}
.rain {
  background-image: url("assets/images/weather/rain.jpg");
}
.mist, .fog, .drizzle {
  background-image: url("assets/images/weather/foggy.jpg");
}
.sunny {
  background-image: url("assets/images/weather/sunny.jpg");
}
.mist {
  background-image: url("assets/images/weather/mist.jpg");
}
.foggy {
  background-image: url("assets/images/weather/foggy.jpg");
}
.clear {
  background-image: url("assets/images/weather/clear.jpg");
}
.light-rain {
  background-image: url("assets/images/weather/light-rain.jpg");
}
.partly-cloudy {
  background-image: url("assets/images/weather/partly-cloudy.jpg");
}
.windy {
  background-image: url("assets/images/weather/windy.jpg");
}
.thunderstorm {
  background-image: url("assets/images/weather/thunderstorm.jpg");
}